var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"tabs"},[_c('router-link',{staticStyle:{"border-bottom":"4px solid #009cff"},attrs:{"to":""}},[_vm._v(_vm._s(_vm.$t('user.login.title')))])],1),_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              { required: true, message: '请输入邮箱地址' },
              { type: 'email', message: '请输入正确邮箱地址' } ],
            validateTrigger: 'change',
          } ]),expression:"[\n          'email',\n          {\n            rules: [\n              { required: true, message: '请输入邮箱地址' },\n              { type: 'email', message: '请输入正确邮箱地址' },\n            ],\n            validateTrigger: 'change',\n          },\n        ]"}],attrs:{"size":"large","type":"text","placeholder":_vm.$t('user.login.emailPlaceholder')}})],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'change' }]),expression:"['password', { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'change' }]"}],attrs:{"size":"large","placeholder":_vm.$t('user.login.passwordPlaceholder')}})],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['rememberMe', { valuePropName: 'checked' }]),expression:"['rememberMe', { valuePropName: 'checked' }]"}]},[_vm._v(_vm._s(_vm.$t('user.login.passwordRemember')))]),_c('router-link',{staticClass:"forge-password",staticStyle:{"float":"right"},attrs:{"to":{ name: 'register', query: { type: 'forgotPassword' } }}},[_vm._v(_vm._s(_vm.$t('user.login.passwordForge')))])],1),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.loginBtn,"disabled":!_vm.form.getFieldsValue().email || !_vm.form.getFieldsValue().password || _vm.loginBtn}},[_vm._v(_vm._s(_vm.$t('user.login.success')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }