<template>
  <div class="main">
    <div class="tabs">
      <router-link to="" style="border-bottom: 4px solid #009cff">{{ $t('user.login.title') }}</router-link>
    </div>
    <a-form id="formLogin" class="user-layout-login" ref="formLogin" :form="form" @submit="handleSubmit">
      <a-form-item>
        <a-input
          size="large"
          type="text"
          :placeholder="$t('user.login.emailPlaceholder')"
          v-decorator="[
            'email',
            {
              rules: [
                { required: true, message: '请输入邮箱地址' },
                { type: 'email', message: '请输入正确邮箱地址' },
              ],
              validateTrigger: 'change',
            },
          ]"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input-password
          size="large"
          :placeholder="$t('user.login.passwordPlaceholder')"
          v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'change' }]"
        >
        </a-input-password>
      </a-form-item>

      <a-form-item>
        <a-checkbox v-decorator="['rememberMe', { valuePropName: 'checked' }]">{{
          $t('user.login.passwordRemember')
        }}</a-checkbox>
        <router-link
          :to="{ name: 'register', query: { type: 'forgotPassword' } }"
          class="forge-password"
          style="float: right"
          >{{ $t('user.login.passwordForge') }}</router-link
        >
      </a-form-item>
      <a-form-item style="margin-top: 24px">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button"
          :loading="loginBtn"
          :disabled="!form.getFieldsValue().email || !form.getFieldsValue().password || loginBtn"
          >{{ $t('user.login.success') }}</a-button
        >
      </a-form-item>
      <!-- <a-form-item >
        <a-checkbox v-decorator="['readProtocol', { valuePropName: 'checked' }]">我已阅读并同意用户协议和隐私政策</a-checkbox>
      </a-form-item> -->
    </a-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { getUserSig } from '@/api/headTeacher';
import { handleUpdateorder } from '@/api/xiaowukong';
import pic_teacher from '@/assets/teacher.png';
import pic_head_charge_lily from '@/assets/icons/pic_head_charge_lily@2x.png';
import { getTimezoneOptions, getCountryOptions } from '@/utils/geoLocation';

const session = storage.createStore(sessionStorage);

export default {
  components: {},
  data() {
    return {
      form: this.$form.createForm(this),
      loginBtn: false,
      formIM: {},
    };
  },
  mounted() {
    // 获取忘记密码后回传的邮箱地址
    if (this.$route.params.email) {
      this.form.setFieldsValue({ email: this.$route.params.email });
    }
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    handleSubmit(e) {
      e.preventDefault();
      const { Login } = this;
      const _this = this;
      // if (this.form.getFieldsValue().readProtocol === undefined) {
      //   this.$message.warning('请先阅读并同意用户协议和隐私政策')
      //   return
      // }
      _this.loginBtn = true;
      const validateFieldsKey = ['email', 'password'];
      _this.form.validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          const loginParams = { ...values };
          loginParams.email = values.email;
          loginParams.password = values.password;
          loginParams.rememberMe = _this.form.getFieldValue('rememberMe');
          Login(loginParams)
            .then((res) => _this.loginSuccess(res))
            .catch((err) => _this.requestFailed(err))
            .finally(() => {
              _this.loginBtn = false;
            });
        } else {
          setTimeout(() => {
            _this.loginBtn = false;
          }, 600);
        }
      });
    },
    loginSuccess(res) {
      /**
       * 教务：TEACHING_STAFF 教务主管：TEACHING_STAFF_ADMIN 班主任主管：HEAD_TEACHER_SUPER 班主任： CLASS_ADMIN
       * 班主任组长：HEAD_TEACHER_LEADER
       */
      // 只有班主任和小悟空角色才能登录
      const roleArr = res.content.roles;
      const roleFLag = roleArr.find(
        (role) =>
          role === 'CLASS_ADMIN' ||
          role === 'TEACHING_STAFF' ||
          role === 'X_WUKONG' ||
          role === 'TEACHING_STAFF_ADMIN' ||
          role === 'HEAD_TEACHER_SUPER' ||
          role === 'HEAD_TEACHER_LEADER',
      );
      if (!roleFLag) {
        this.$store.commit('showMessage', {
          message: '此账号暂无登录权限',
          type: 'error',
        });
        return false;
      }
      this.$store.commit('SET_INFO', res.content);
      // 获取用户的userid
      this.formIM.userID = res.content.uuid;
      const obj = {
        email: res.content.email,
        fullName: res.content.fullName,
        roleName: roleFLag,
        userID: res.content.uuid,
        phone: res.content.phone,
        subject: res.content.subject,
        roleArr,
        leader: res.content.leader,
        leaderDeptId: res.content.leaderDeptId, // 最高部门的部门ID
      };
      storage.set('userInfo', obj);

      // 存储用户头像

      const avatar = roleFLag === 'CLASS_ADMIN' ? pic_teacher : pic_head_charge_lily;
      if (res.content.accountData !== null && res.content.accountData.avatar) {
        storage.set('avatarIM', res.content.accountData.avatar);
      } else {
        storage.set('avatarIM', avatar);
      }

      // 如果角色是班主任或者小悟空，登录IM
      this._getUserSig();

      // 缓存时区信息
      getTimezoneOptions().then((timezoneOptions) => {
        localStorage.timezoneOptions = JSON.stringify(timezoneOptions);
        localStorage.timezoneMaps = JSON.stringify(
          timezoneOptions?.reduce(
            (o, item) => ({
              ...o,
              [item.value]: item.label,
            }),
            {},
          ),
        );
      });
      // 缓存国家信息
      getCountryOptions().then((countryOptions) => {
        localStorage.countryOptions = JSON.stringify(countryOptions);
        localStorage.countryMaps = JSON.stringify(
          countryOptions?.reduce(
            (o, item) => ({
              ...o,
              [item.value]: item.label,
            }),
            {},
          ),
        );
      });
    },
    // 获取IM的UserSig
    _getUserSig() {
      getUserSig().then((res) => {
        this.formIM.userSig = res.data.content.userSig;
        this.formIM.sdkAppId = res.data.content.sdkAppId;
        session.set('sdkAppId', this.formIM.sdkAppId);
        this.loginIM();
      });
    },
    // 登录IM
    loginIM() {
      this.tim
        .login({
          userID: this.formIM.userID,
          userSig: this.formIM.userSig,
        })
        .then(() => {
          this.$router.push({ path: '/' });
          // setTimeout(() => {
          //   this.$notification.success({
          //     message: '欢迎'
          //     // description: `${timeFix()}，欢迎回来`
          //   })
          // }, 1000)
          // 刷新浏览器，IM会断开vuex会清空useSig
          const obj = {
            userID: this.formIM.userID,
            userSig: this.formIM.userSig,
          };

          // 如果记住密码，也要记住IM的登录
          // if (this.form.getFieldValue('rememberMe')) {
          //   storage.set('userObj', obj, 7 * 24 * 60 * 60 * 1000);
          // } else {
          //   session.set('userObj', obj);
          // }
          storage.set('userObj', obj, 7 * 24 * 60 * 60 * 1000);

          this.$store.commit('startComputeCurrent');

          this.$store.commit({
            type: 'GET_USER_INFO',
            userID: this.formIM.userID,
            userSig: this.formIM.userSig,
            sdkAppID: this.formIM.sdkAppId,
          });
          // 小悟空 登录调用接单
          const roles = storage.get('roles');
          const roleFLag = roles.find((role) => role === 'CLASS_ADMIN' || role === 'X_WUKONG');

          if (storage.get('userInfo').roleArr.includes('X_WUKONG')) {
            this.handlechange();
          }
        })
        .catch((error) => {
          // this.loading = false
          // this.$store.commit('showMessage', {
          //   message: '登录失败：' + error.message,
          //   type: 'error'
          // })
        });
    },
    // TODU 小悟空接单逻辑 角色是小悟空时 才调用
    handlechange() {
      const parms = {
        id: this.formIM.userID,
        type: 'online',
        batch: false,
      };
      handleUpdateorder(parms).then((res) => {
        // console.log('小悟空接单逻辑down')
        if (res.status != 200) {
        }
      });
    },

    failedfuc(err) {
      if (err?.data?.errors && err?.data?.errors?.email === 'User not found') {
        this.$store.commit('showMessage', {
          message: '登录失败：该邮箱还未注册',
          type: 'error',
        });
      } else if (err?.data?.errors && err?.data?.errors?.password === 'Incorrect password') {
        this.$store.commit('showMessage', {
          message: '登录失败：密码不正确',
          type: 'error',
        });
      }
    },
    requestFailed(err) {
      this.failedfuc(err.response);
    },
  },
};
</script>
<style lang="less">
.user-layout-login {
  .ant-input-password .ant-input-lg {
    height: 64px;
    border-radius: 14px;
    &:hover {
      border-color: #009cff !important;
      border-right-width: 1px !important;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #009cff;
    border: #009cff;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #009cff;
  }
}
</style>
<style lang="less" scoped>
.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  text-align: center;
  & a {
    display: inline-block;
    width: 80px;
    height: 50px;
    font-size: 24px;
    line-height: 50px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333 !important;
  }
}
.user-layout-login {
  label {
    font-size: 14px;
  }
  .forge-password {
    color: #009cff;
    font-size: 14px;
  }

  // .ant-row{
  //   margin-top: 36px;
  // }
  .ant-form-item {
    margin-bottom: 36px;
  }
  .ant-form-item-with-help {
    margin-bottom: 17px;
  }
  .ant-input,
  .ant-input-password .ant-input-lg {
    height: 64px;
    border-radius: 14px;
  }
  & > h3 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .ant-input:hover {
    border-color: #009cff !important;
  }
  .login-button {
    height: 64px;
    border-radius: 14px;
    width: 100%;
    font-size: 24px;
    color: #fff;
    // background-color: #009CFF;
    border: none;
  }
}
</style>
